import { Container, Navbar, Button, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { constant } from "../../constant/constant";

export default function Header() {
  return (
    <Navbar className="bg-body-tertiary" style={{ color: "#fff" }}>
      <Container>
        <Row>
          <Col xs="auto" className=" mb-3 mt-3">
            <Navbar.Brand href="#">
              <img
                alt=""
                src="maxlogo.svg"
                width="79"
                height="48"
                className="d-inline-block align-top"
              />{" "}
            </Navbar.Brand>
          </Col>
          <Col xs="auto" className="label-color text-center mb-3 mt-3">
            {constant.REACT_APP_Term_Insurance}
          </Col>
          <Col xs="auto" className="label-color text-center mb-3 mt-3">
            {constant.REACT_APP_Investment_Plans}
          </Col>
          <Col xs="auto" className="label-color text-center mb-3 mt-3">
            {constant.REACT_APP_Health_Insurance_Plans}
          </Col>
          <Col xs="auto" className="label-color text-center mb-3 mt-3">
            {constant.REACT_APP_Renew_Policy}
          </Col>
          <Col xs="auto" className=" mb-3 mt-3">
            <Button className="header-button-color label-color">
              {constant.REACT_APP_Track_Application}
            </Button>
          </Col>
          <Col xs="auto" className=" mb-3 mt-3">
            <Button className="customer-button-color customer-label-color">
              {constant.REACT_APP_Customer_Login}
            </Button>
          </Col>
        </Row>
      </Container>
    </Navbar>
  );
}
