import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import "./formcard.css";
import { constant } from "../../constant/constant";

function FormCard(props) {
  return (
    <Container className="min-vh-75">
      <Row xs={1} md={2} lg={2} className="g-4">
        <Col className="d-flex flex-column justify-content-center">
          <p className="text-center fs-2 fw-bold">
            {constant.REACT_APP_ABHA_CardTitle}
          </p>
          <p className="content-text">{constant.REACT_APP_ABHA_INFO}</p>
        </Col>
        <Col>
          <Card className="bg-white text-dark card-form pb-5">
            <Card.Title className="text-center font-bold p-4" as="h2">
              {props.title}
            </Card.Title>
            <Card.Text className="card-form-body p-4">
              {props.children}
            </Card.Text>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default FormCard;
