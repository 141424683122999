import React, { useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import LoadingSpinner from "../common/LoadingSpinner";
import apiService from "../../service/apiService";
import { constant } from "../../constant/constant";

export default function MobileVerification(props) {
  const [mobileNumber, setMobileNumber] = useState("");
  const [invalidInput, setInvalidInput] = useState(false);
  const [invalidOTPInput, setInvalidOTPInput] = useState(false);
  const [generateOtpButtonDisabled, setGenerateOtpButtonDisabled] =
    useState(true);
  const [mobileNumberInputDisabled, setMobileNumberInputDisabled] =
    useState(false);
  const [verifyOtp, setVerifyOtp] = useState(false);
  const [otpInput, setOtpInput] = useState("");
  const [buttonLabel, setButtonLabel] = useState("Verify");
  const [validInput, setValidInput] = useState(false);
  const [hideLoader, setHideLoader] = useState(true);
  const [mobileNumberInputMessage, setMobileNumberInputMessage] = useState("");
  const [otpInputMessage, setOtpInputMessage] = useState("");
  const [resendOtpButtonDisabled, setResendOtpButtonDisabled] = useState(false);

  const onOTPInputChange = (e) => {
    if (e.target.value.length <= 6) {
      setOtpInput(e.target.value);
      setInvalidOTPInput(false);
    }
  };

  const setMobileNumberInput = (e) => {
    let inputVal = e.target.value;
    if (/^\d+$/.test(inputVal)) {
      if (inputVal.length > 10) {
        return;
      }
      setMobileNumber(inputVal);
      if (inputVal.length === 10) {
        setInvalidInput(false);
        setGenerateOtpButtonDisabled(false);
      } else if (inputVal.length < 10) {
        setInvalidInput(true);
        setGenerateOtpButtonDisabled(true);
        setMobileNumberInputMessage(constant.REACT_APP_VALID_MOBILE_NUMBER);
      }
    } else if (!inputVal) {
      setMobileNumber("");
      setInvalidInput(false);
      setGenerateOtpButtonDisabled(true);
    }
  };

  const resendOtp = (e) => {
    setResendOtpButtonDisabled(true);
    let request_body = {
      mobile: mobileNumber,
      txnId: sessionStorage.getItem(constant.REACT_APP_RESEND_OTP_TXNID),
      verifyMobile: constant.REACT_APP_TRUE,
    };
    apiService(constant.REACT_APP_ALTERNATE_MOBILE_LINK, request_body)
      .then((response) => {
        if (response.data) {
          if (response.data.errorMessage) {
            setInvalidOTPInput(true);
            setOtpInputMessage(response.data.errorMessage);
          } else {
            sessionStorage.setItem(
              constant.REACT_APP_txnId,
              response.data.txnId
            );
          }
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setResendOtpButtonDisabled(false);
      });
  };

  const submitMVForm = (e) => {
    e.preventDefault();
    setHideLoader(false);
    setGenerateOtpButtonDisabled(true);
    if (verifyOtp) {
      let request_body = {
        alternateMobile: constant.REACT_APP_TRUE,
        txnId: sessionStorage.getItem(constant.REACT_APP_txnId),
        otp: otpInput,
      };

      apiService(constant.REACT_APP_ALTERNATE_MOBILE_LINK, request_body)
        .then((response) => {
          if (response.data) {
            if (response.data.errorMessage) {
              setInvalidOTPInput(true);
              setOtpInputMessage(response.data.errorMessage);
            } else {
              sessionStorage.setItem(
                constant.REACT_APP_token,
                response.data.token
              );
              props.setAbhaAddressForm();
            }
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setHideLoader(true);
          setGenerateOtpButtonDisabled(false);
        });
    } else if (buttonLabel === constant.REACT_APP_Proceed) {
      let request_body = {
        txnId: sessionStorage.getItem(constant.REACT_APP_txnId),
        createHealthCard: constant.REACT_APP_TRUE,
      };
      apiService(constant.REACT_APP_CREATE_HEALTH_ID, request_body)
        .then((response) => {
          if (response.data) {
            sessionStorage.setItem(
              constant.REACT_APP_token,
              response.data.token
            );
            props.setAbhaAddressForm();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      sessionStorage.setItem(
        constant.REACT_APP_RESEND_OTP_TXNID,
        sessionStorage.getItem(constant.REACT_APP_txnId)
      );
      let request_body = {
        mobile: mobileNumber,
        txnId: sessionStorage.getItem(constant.REACT_APP_txnId),
        verifyMobile: constant.REACT_APP_TRUE,
      };
      apiService(constant.REACT_APP_API_VERIFY_AND_LINK, request_body)
        .then((response) => {
          if (response.data) {
            if (response.data.errorMessage) {
              setInvalidInput(true);
              setMobileNumberInputMessage(response.data.errorMessage);
            } else {
              sessionStorage.setItem(
                constant.REACT_APP_txnId,
                response.data.txnId
              );
              if (response.data.mobileLinked === constant.REACT_APP_TRUE) {
                setValidInput(true);
                setMobileNumberInputMessage(
                  constant.REACT_APP_CHECK_MOBILE_LINKED_WITH_AADHAR
                );
                setMobileNumberInputDisabled(true);
                setButtonLabel(constant.REACT_APP_Proceed);
              } else {
                setVerifyOtp(true);
                setMobileNumberInputDisabled(true);
                setButtonLabel(constant.REACT_APP_Proceed);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setHideLoader(true);
          setGenerateOtpButtonDisabled(false);
        });
    }
  };
  return (
    <Form onSubmit={submitMVForm}>
      <Row className="mb-3" xs={1} lg={1}>
        <Form.Group as={Col} className="mb-2">
          <p className="content-text fw-bold">
            It is recommended to use the mobile number you have linked with
            Aadhaar no.
          </p>
        </Form.Group>
        <Form.Group as={Col} className="mb-2">
          <InputGroup>
            <InputGroup.Text id="basic-addon1">+91</InputGroup.Text>
            <Form.Control
              placeholder={constant.REACT_APP_MOBILE_NUMBER}
              value={mobileNumber}
              onChange={setMobileNumberInput}
              isInvalid={invalidInput}
              aria-label="mobile"
              aria-describedby="basic-addon1"
              autoComplete="off"
              disabled={mobileNumberInputDisabled}
              isValid={validInput}
              required
            />
            <Form.Control.Feedback type="invalid">
              {mobileNumberInputMessage}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid">
              {mobileNumberInputMessage}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col} className="mb-3"></Form.Group>
      </Row>
      {verifyOtp ? (
          <Row className="mb-2" xs={1} lg={1}>
            <Col>
              <Form.Group as={Col} className="mb-3">
                <InputGroup className="mb-2">
                  <Form.Control
                    type="text"
                    placeholder={constant.REACT_APP_Enter_OTP}
                    isInvalid={invalidOTPInput}
                    onChange={onOTPInputChange}
                    value={otpInput}
                    autoComplete="off"
                    required
                    minLength={6}
                    maxLength={6}
                  />
                  <Button
                    variant="outline-primary"
                    className="outline-button-color"
                    disabled={resendOtpButtonDisabled}
                    onClick={resendOtp}
                  >
                    Resend OTP
                  </Button>
                  <Form.Control.Feedback type="invalid">
                    {otpInputMessage}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
      ) : (
        <></>
      )}
      <Row className="mb-2" xs={1} lg={1}>
        <Col>
          <Button
            variant="primary"
            className="w-100 button-color"
            type="submit"
            disabled={generateOtpButtonDisabled}
          >
            <LoadingSpinner show={hideLoader} />
            {buttonLabel}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
