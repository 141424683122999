import React, { useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import LoadingSpinner from "../common/LoadingSpinner";
import "./searchAbha.css";
import apiService from "../../service/apiService.js";
import { constant } from "../../constant/constant";

const SearchAbha = (props) => {
  const [abdmAddress, setAbdmAddress] = useState("");
  const [isValidInput, setIsValidInput] = useState(false);
  const [isAbhaNumberValid, setIsAbhaNumberValid] = useState("");
  const [showDivabhaAddressOtp, setShowDivabhaAddressOtp] = useState(false);
  const [abhaAddressOtpValid, setAbhaAddressOtpValid] = useState(false);
  const [showDivabhaNumberOtp, setShowDivabhaNumberOtp] = useState(false);
  const [abhaNumberOtpValid, setAbhaNumberOtpValid] = useState(false);
  const [abhaNumberInputValue, setAbhaNumberInputValue] = useState("");
  const [WrongAbhaNumberError, setWrongAbhaNumberError] = useState(null);
  const [ResendWrongAbhaNumberError, setResendWrongAbhaNumberError] =
    useState(null);
  const [ResendWrongAbhaAddressError, setResendWrongAbhaAddressError] =
    useState(null);
  const [WrongAbhaNumberOtpError, setWrongAbhaNumberOtpError] = useState(null);
  const [WrongAbhaAddressError, setWrongAbhaAddressError] = useState(null);
  const [WrongAbhaAddressOtpError, setWrongAbhaAddressOtpError] =
    useState(null);
  const [unformattedAbhaNumber, setUnformattedAbhaNumber] = useState("");
  const [abhaAddressOtp, setAbhaAddressOtp] = useState("");
  const [abhaNumberOtp, setAbhaNumberOtp] = useState("");
  const [hideLoader, setHideLoader] = useState(true);
  const [selectedCheckbox, setSelectedCheckbox] = useState(null);
  const [isVerifyAbhaNumberClicked, setIsVerifyAbhaNumberClicked] =
    useState(false);
  const [isVerifyAbhaAddressClicked, setIsVerifyAbhaAddressClicked] =
    useState(false);
  const [isViewCardfromAbhaNumberClicked, setIsViewCardfromAbhaNumberClicked] =
    useState(false);
  const [
    isViewCardfromAbhaAddressClicked,
    setIsViewCardfromAbhaAddressClicked,
  ] = useState(false);

  const handleCheckboxChange = (value) => {
    setSelectedCheckbox(value);
    if (value === "checkbox2") {
      setShowDivabhaAddressOtp(false);
      setAbdmAddress("");
      setIsValidInput(false);
    }
    if (value === "checkbox3") {
      setAbhaNumberInputValue("");
      setShowDivabhaNumberOtp(false);
    }
  };

  const setAbdmAdress = (e) => {
    let abdmAddress = e.target.value.toLowerCase();
    const isValid =/^[a-zA-Z0-9._]*$/.test(abdmAddress) && abdmAddress.length > 0;
    setAbdmAddress(abdmAddress);
    setIsValidInput(isValid);
    if (isValid) setIsVerifyAbhaAddressClicked(false);};
  

  const handleAbhaNumberChange = (event) => {
    const abhaNumberInputValue = event.target.value;
    const digitsOnly = abhaNumberInputValue.replace(/\D/g, "");
    let formattedInput = "";
    for (let i = 0; i < digitsOnly.length; i++) {
      if (i === 2 || i === 6 || i === 10) {
        formattedInput += "-";
      }
      formattedInput += digitsOnly[i];
    }
    const isAbhaNumValid = /^\d{2}-\d{4}-\d{4}-\d{4}$/.test(formattedInput);
    setAbhaNumberInputValue(formattedInput);
    setIsAbhaNumberValid(isAbhaNumValid);
    setUnformattedAbhaNumber(digitsOnly);
    if (isAbhaNumValid) setIsVerifyAbhaNumberClicked(false);
  };

  const handleButtonClickForVerify = async () => {
    setHideLoader(false);
    setIsVerifyAbhaAddressClicked(true);
    try {
      const requestBody = {
        authInit: constant.REACT_APP_TRUE,
        authMethod: constant.REACT_APP_MOBILE_OTP,
        healthId: abdmAddress,
      };

      const response = await apiService(
        constant.REACT_APP_API_URL_AUTHNIT,
        requestBody
      );

      setHideLoader(true);
      if (response.data.errorMessage) {
        setWrongAbhaAddressError(response.data.errorMessage);
      } else {
        sessionStorage.setItem(constant.REACT_APP_txnId, response.data.txnId);
        setWrongAbhaAddressError(null);
        setShowDivabhaAddressOtp(true);
      }
    } catch (error) {
      if (error.response && error.response.status === 504) {
        setResendWrongAbhaAddressError(constant.GATEWAY_TIMEOUT_API);
      }
      setHideLoader(true);
    }
  };

  const ResendhandleButtonClickForVerify = async () => {
    const requestBody = {
      authInit: constant.REACT_APP_TRUE,
      authMethod: constant.REACT_APP_MOBILE_OTP,
      healthId: abdmAddress,
    };

    const response = await apiService(
      constant.REACT_APP_API_URL_AUTHNIT,
      requestBody
    );
    try {
      if (response.data.errorMessage) {
        setResendWrongAbhaAddressError(response.data.errorMessage);
      } else {
        sessionStorage.setItem(constant.REACT_APP_txnId, response.data.txnId);
      }
    } catch (error) {
      if (error.response && error.response.status === 504) {
        setResendWrongAbhaAddressError(constant.GATEWAY_TIMEOUT_API);
      }
    }
  };

  const verifyAbhaAddressOtp = (event) => {
    const abhaAddressOtp = event.target.value;
    const abhaAddressOtpValidity = abhaAddressOtp.length === 6;
    setAbhaAddressOtp(abhaAddressOtp);
    setAbhaAddressOtpValid(abhaAddressOtpValidity);
    if (abhaAddressOtpValidity) setIsViewCardfromAbhaAddressClicked(false);
  };

  const ProceedWithAbhaAddressOtp = async () => {
    setHideLoader(false);
    setIsViewCardfromAbhaAddressClicked(true);
    const requestBody = {
      authSessionMobileOtp: constant.REACT_APP_TRUE,
      txnId: sessionStorage.getItem(constant.REACT_APP_txnId),
      otp: abhaAddressOtp,
    };
    const response = await apiService(
      constant.REACT_APP_API_URL_VERIFY_AUTH_MOBILE_OTP,
      requestBody
    );
    setHideLoader(true);
    if (response.data.errorMessage) {
      setWrongAbhaAddressOtpError(response.data.errorMessage);
    } else {
      sessionStorage.setItem(constant.REACT_APP_token, response.data.token);
      props.setDownloadAbhaForm(response.data);
    }
  };

  const handleButtonClickForVerifyAbhaNumber = async () => {
    setHideLoader(false);
    setIsVerifyAbhaNumberClicked(true);
    const requestBody = {
      authInit: constant.REACT_APP_TRUE,
      authMethod: constant.REACT_APP_MOBILE_OTP,
      healthId: unformattedAbhaNumber,
    };
    const response = await apiService(
      constant.REACT_APP_API_URL_AUTHNIT,
      requestBody
    );

    setHideLoader(true);
    if (response.data.errorMessage) {
      setWrongAbhaNumberError(response.data.errorMessage);
    } else {
      sessionStorage.setItem(constant.REACT_APP_txnId, response.data.txnId);
      setShowDivabhaNumberOtp(true);
    }
  };

  const ResendhandleButtonClickForVerifyAbhaNumber = async () => {
    const requestBody = {
      authInit: constant.REACT_APP_TRUE,
      authMethod: constant.REACT_APP_MOBILE_OTP,
      healthId: unformattedAbhaNumber,
    };

    const response = await apiService(
      constant.REACT_APP_API_URL_AUTHNIT,
      requestBody
    );

    try {
      if (response.data.errorMessage) {
        setResendWrongAbhaNumberError(response.data.errorMessage);
      } else {
        sessionStorage.setItem(constant.REACT_APP_txnId, response.data.txnId);
        setWrongAbhaNumberError(null);
      }
    } catch (error) {
      if (error.response && error.response.status === 504) {
        setResendWrongAbhaNumberError(constant.REACT_APP_GATEWAY_TIMEOUT_API);
      }
    }
  };

  const ProceedWithAbhaNumberOtp = async () => {
    setHideLoader(false);
    setIsViewCardfromAbhaNumberClicked(true);
    const requestBody = {
      authSessionMobileOtp: constant.REACT_APP_TRUE,
      txnId: sessionStorage.getItem(constant.REACT_APP_txnId),
      otp: abhaNumberOtp,
    };

    const response = await apiService(
      constant.REACT_APP_API_URL_VERIFY_AUTH_MOBILE_OTP,
      requestBody
    );

    setHideLoader(true);
    if (response.data.errorMessage) {
      setWrongAbhaNumberOtpError(response.data.errorMessage);
    } else {
      sessionStorage.setItem(constant.REACT_APP_token, response.data.token);
      props.setDownloadAbhaForm(response.data);
    }
  };

  const verifyAbhaNumberOtp = (event) => {
    const abhaNumberOtp = event.target.value;
    const abhaNumberOtpValidity = abhaNumberOtp.length === 6;
    setAbhaNumberOtp(abhaNumberOtp);
    setAbhaNumberOtpValid(abhaNumberOtpValidity);
    if (abhaNumberOtpValidity) setIsViewCardfromAbhaNumberClicked(false);
  };

  return (
    <Row className="mb-3" xs={1} lg={1}>
      <Col>
        <h6 style={{ marginTop: "3%" }}>
          Search your ABHA card, using below options
        </h6>
        <div className="search-radio-btns">
          <div>
            <input
              type="radio"
              name="search-aabha"
              className="radio-label"
              value=""
              checked={selectedCheckbox === "checkbox2"}
              onChange={() => handleCheckboxChange("checkbox2")}
            />{" "}
            <label for="">ABHA Number</label>
          </div>
          <div>
            <input
              type="radio"
              name="search-aabha"
              className="radio-label"
              value=""
              checked={selectedCheckbox === "checkbox3"}
              onChange={() => handleCheckboxChange("checkbox3")}
            />{" "}
            <label for="">ABHA Address</label>
          </div>
        </div>
        {selectedCheckbox === "checkbox2" && (
          <div>
            <InputGroup className="mb-3">
              <Form.Control
                placeholder={constant.REACT_APP_Enter_ABHA_Number}
                value={abhaNumberInputValue}
                onChange={handleAbhaNumberChange}
                autoComplete="off"
                maxlength="17"
              />
            </InputGroup>
            {WrongAbhaNumberError && !showDivabhaNumberOtp && (
              <div className="error-message" style={{ color: "red" }}>
                {WrongAbhaNumberError}
              </div>
            )}
            {showDivabhaNumberOtp && (
              <div className="mb-2">
                <InputGroup className="mb-2">
                  <Form.Control
                    placeholder={constant.REACT_APP_Enter_OTP}
                    maxlength="6"
                    value={abhaNumberOtp}
                    onChange={verifyAbhaNumberOtp.bind(this)}
                    autoComplete="off"
                  />
                  <Button
                    variant="outline-primary"
                    className="outline-button-color"
                    value={unformattedAbhaNumber}
                    onClick={ResendhandleButtonClickForVerifyAbhaNumber}
                  >
                    Resend OTP
                  </Button>
                </InputGroup>
              </div>
            )}
            {ResendWrongAbhaNumberError && !WrongAbhaNumberOtpError && (
              <div className="error-message" style={{ color: "red" }}>
                {ResendWrongAbhaNumberError}
              </div>
            )}
            {WrongAbhaNumberOtpError && (
              <div className="error-message" style={{ color: "red" }}>
                {WrongAbhaNumberOtpError}
              </div>
            )}
            <div style={{ marginTop: "10%" }}></div>
            {!showDivabhaNumberOtp && (
              <Button
                variant="primary"
                className="w-100 button-color"
                type="submit"
                disabled={!isAbhaNumberValid || isVerifyAbhaNumberClicked}
                onClick={handleButtonClickForVerifyAbhaNumber}
                value={unformattedAbhaNumber}
              >
                <LoadingSpinner show={hideLoader} /> Verify & Proceed
              </Button>
            )}
            {showDivabhaNumberOtp && (
              <Button
                variant="primary"
                className="w-100 button-color"
                type="submit"
                disabled={
                  !abhaNumberOtpValid || isViewCardfromAbhaNumberClicked
                }
                onClick={ProceedWithAbhaNumberOtp}
                value={abhaNumberOtp}
              >
                <LoadingSpinner show={hideLoader} /> View/Download ABHA Card
              </Button>
            )}
            <p>
              By continuing, you agree to Maxlife Terms of Services & Privacy
              Policy
            </p>
          </div>
        )}
        {selectedCheckbox === "checkbox3" && (
          <div>
            <InputGroup className="mb-3">
              <Form.Control
                placeholder={constant.REACT_APP_Enter_ABHA_Address}
                value={abdmAddress}
                onChange={setAbdmAdress.bind(this)}
                aria-label="abdm-address"
                aria-describedby="basic-addon1"
                autoComplete="off"
                maxLength="32"
                minLength="4"
              />
              <InputGroup.Text id="basic-addon1">@sbx</InputGroup.Text>
            </InputGroup>
            {WrongAbhaAddressError && !showDivabhaAddressOtp && (
              <div className="error-message" style={{ color: "red" }}>
                {WrongAbhaAddressError}
              </div>
            )}
            {isValidInput && !WrongAbhaAddressError ? (
              <p style={{ color: "green" }}>
                Valid characters for abha address
              </p>
            ) : (
              !isValidInput &&
              abdmAddress !== "" && (
                <p style={{ color: "red" }}>Special characters except underscore and dot are not valid</p>
              )
            )}
            {showDivabhaAddressOtp && (
              <div className="mb-2">
                <InputGroup className="mb-2">
                  <Form.Control
                    placeholder={constant.REACT_APP_Enter_OTP}
                    maxlength="6"
                    value={abhaAddressOtp}
                    onChange={verifyAbhaAddressOtp.bind(this)}
                    autoComplete="off"
                  />

                  <Button
                    variant="outline-primary"
                    className="outline-button-color"
                    onClick={ResendhandleButtonClickForVerify}
                    value={abdmAddress}
                  >
                    Resend OTP
                  </Button>
                </InputGroup>
              </div>
            )}
            {ResendWrongAbhaAddressError && !WrongAbhaAddressOtpError && (
              <div className="error-message" style={{ color: "red" }}>
                {ResendWrongAbhaAddressError}
              </div>
            )}
            {WrongAbhaAddressOtpError && (
              <div className="error-message" style={{ color: "red" }}>
                {WrongAbhaAddressOtpError}
              </div>
            )}
            <div style={{ marginTop: "10%" }}></div>
            {!showDivabhaAddressOtp && (
              <Button
                variant="primary"
                className="w-100 button-color"
                type="submit"
                disabled={!isValidInput || isVerifyAbhaAddressClicked}
                onClick={handleButtonClickForVerify}
                value={abdmAddress}
              >
                <LoadingSpinner show={hideLoader} /> Verify & Proceed
              </Button>
            )}
            {showDivabhaAddressOtp && (
              <Button
                variant="primary"
                className="w-100 button-color"
                type="submit"
                disabled={
                  !abhaAddressOtpValid || isViewCardfromAbhaAddressClicked
                }
                onClick={ProceedWithAbhaAddressOtp}
                value={abhaAddressOtp}
              >
                <LoadingSpinner show={hideLoader} /> View/Download ABHA Card
              </Button>
            )}
            <p>
              By continuing, you agree to Maxlife Terms of Services & Privacy
              Policy
            </p>
          </div>
        )}
      </Col>
    </Row>
  );
};

export default SearchAbha;
