import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import "./formcard.css";
import { constant } from "../../constant/constant";

const SchemeBenefits = ({ benefits }) => {
  return (
    <div>
      <div className="benefits-container">
        {benefits.map((benefit, index) => (
          <div key={index} className="benefit-box">
            <p>{benefit.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

const schemeBenefitsData = [
  { description: constant.REACT_APP_description1 },
  { description: constant.REACT_APP_description2 },
  { description: constant.REACT_APP_description3 },
  { description: constant.REACT_APP_description4 },
  { description: constant.REACT_APP_description5 },
  { description: constant.REACT_APP_description6 },
  { description: constant.REACT_APP_description7 },
  { description: constant.REACT_APP_description8 },
];

const AbhaCardContent = () => {
  return (
    <Container className="min-vh-100">
      <Row xs={1} md={1} lg={1} className="g-4">
        <Col>
          <Card className="bg-white text-dark card-form pb-5">
            <Card.Title
              className="text-center font-bold p-4"
              as="h2"
            ></Card.Title>
            <Card.Text className="card-form-body p-4">
              <div>
                <h3> What is Ayushman Bharat Health Account (ABHA Card)?</h3>
                <p>
                  <br /> ABHA card, also known as ABHA health card, is a
                  healthcare ID card offered by the government of India to its
                  citizens. This card comprises a 14-digit identification number
                  obtained using your mobile number and your AADAR number. The
                  Ayushman Bharat Health Account card or ABHA card allows you to
                  maintain all your medical history, such as past diagnostic
                  reports, digitally on a single platform without any hassle of
                  keeping extensive files.
                  <br />
                  <br /> By integrating the user’s medical records and creating
                  a single-point and safe access, the initiative allows the user
                  to share these details with healthcare centres and medical
                  professionals across India. Users can also control the use of
                  their healthcare records, and access is subject to consent.
                </p>
                <h3>Benefits of the ABHA card</h3>
                <p>
                  <br />
                  Under the Ayushman Bharat Digital Mission, you can access the
                  health benefits of the Ayushman Bharat Yojana scheme with your
                  ABHA card. This feature is available for all, Indian citizens
                  who want to avail of healthcare assistance and benefits with
                  their ABHA card.
                  <br /> &nbsp;
                  <br />
                  Some benefits you can access under the Ayushman Bharat Yojana
                  scheme with your ABHA card are:
                </p>
                <SchemeBenefits benefits={schemeBenefitsData.slice(0, 4)} />
                <SchemeBenefits benefits={schemeBenefitsData.slice(4)} />
                <br />
                <h3>
                  How To Apply for ABHA Health ID Card Registration Online?
                  <br />
                </h3>
                <p>
                  <br />
                  You can apply for your ABHA digital health card online using
                  your Aadhaar card.If your Aadhar card is linked to your mobile
                  number, you can use it to generate your ABHA health ID and
                  receive an OTP on your mobile number to verify the
                  application.&nbsp;
                  <br /> &nbsp;
                </p>
                <h2>
                  Frequently Asked Questions (FAQs) About Ayushman Bharat Health
                  Account
                </h2>
                <p>
                  <b>
                    What are the eligibility criteria to register for an ABHA
                    card?
                  </b>
                </p>
                <p>
                  You can register for an ABHA card if you are a citizen of
                  India.
                </p>
                <p>
                  <b>What is the benefit of an ABHA card?</b>
                </p>
                <p>Some of the basic benefits of having an ABHA card are:</p>
                <ul>
                  <li>
                    <p>
                      Access to the Healthcare Professional Registry (HPR) in
                      India
                    </p>{" "}
                  </li>
                  <li>
                    <p>
                      Easy access to all your medical information,
                      prescriptions, etc.
                    </p>{" "}
                  </li>
                  <li>
                    <p>
                      Avail of AYUSH treatment such as Ayurveda, Unani,
                      Homoeopathy, etc.
                    </p>{" "}
                  </li>
                  <li>
                    <p>
                      Seamless sign up for PHR (Personal Health Records)
                      applications like ABDM ABHA application for Health data
                      sharing
                    </p>{" "}
                  </li>
                  <li>
                    <p>
                      Get unified benefits under one card by linking all you
                      public health programmes and insurance schemes to your
                      ABHA card.
                    </p>{" "}
                  </li>
                </ul>
                <p>
                  <b>What is the use of the ABHA number?</b>
                </p>
                <p>
                  The ABHA number is a 14-digit number that is generated and
                  used to identify and authenticate the user. The number is also
                  used to link the user’s health records to other systems in the
                  healthcare industry. However, this can only be done with the
                  user’s informed consent.
                </p>
                <p>
                  <b>Who is eligible for an Ayushman card?</b>
                </p>
                <p>
                  You can avail of the Ayushman card if your household is listed
                  in the Socio-Economic and Caste Census (SECC) database and if
                  you are an active Rashtriya Swasthya Bima Yojana (RSBY)
                  cardholder.
                </p>
                <p>
                  <b>Eligibility Criteria for Rural Users</b>
                </p>
                <ul>
                  <li>
                    <p>
                      Any household without a male or adult earning member
                      between 16-59 years of age.
                    </p>{" "}
                  </li>
                  <li>
                    <p>
                      Households with one disabled member but no healthy adult.
                    </p>{" "}
                  </li>
                  <li>
                    <p>
                      Households with no members between 16-59 years of age.
                    </p>{" "}
                  </li>
                  <li>
                    <p>
                      Households without land earning the main portion of their
                      income through manual labour.
                    </p>{" "}
                  </li>
                  <li>
                    <p>
                      Families living in one room with kutcha walls and roofs
                      made of grass, bamboo, mud, etc.
                    </p>{" "}
                  </li>
                  <li>
                    <p>
                      Families earning a livelihood through manual scavenging.
                    </p>{" "}
                  </li>
                </ul>
                <p>
                  <b>Eligibility Criteria for Urban Users</b>
                </p>
                <ul>
                  <li>
                    <p>Domestic workers/gardeners/security guards</p>{" "}
                  </li>
                  <li>
                    <p>Beggars/ragpickers</p>{" "}
                  </li>
                  <li>
                    <p>Street vendors/cobblers</p>{" "}
                  </li>
                  <li>
                    <p>Home-based artisans/ tailors/craftsmen</p>{" "}
                  </li>
                  <li>
                    <p>Sanitation workers/plumber/ washerman</p>{" "}
                  </li>
                  <li>
                    <p>Electrician/mechanic/assembler/mason</p>{" "}
                  </li>
                  <li>
                    <p>Construction workers/painter/welder</p>{" "}
                  </li>
                  <li>
                    <p>
                      Transport worker/rickshaw puller/ coolie/conductor/cart
                      puller
                    </p>{" "}
                  </li>
                  <li>
                    <p>
                      Servers in restaurants/shop workers/delivery
                      assistants/peons
                    </p>{" "}
                  </li>
                </ul>
                <p>
                  <b>Is the Ayushman Bharat card free?</b>
                </p>
                <p>
                  Registering and getting an ABHA card is completely free of
                  cost.
                </p>
                <p>
                  <b>
                    What is the eligibility criteria to register for an ABHA
                    card?
                  </b>
                </p>
                <p>
                  You can register for an ABHA card if you are a citizen of
                  India.
                </p>
                <p>
                  <b>Can we claim medical bills with the Ayushman card?</b>
                </p>
                <p>
                  If you are an Ayushman Bharat Health Account beneficiary, you
                  will be eligible for the ABHA health card. This card can help
                  you avail of cashless treatment and hospitalisation under the
                  scheme.
                </p>
                <p>
                  <b>Is the Arogya card and the Ayushman card the same?</b>
                </p>
                <p>
                  No, there is a difference between the Arogya and the Ayushman
                  cards. The ABHA card allows the economically weaker sections
                  of society to access cashless medical care in a list of
                  hospitals public or private hospitals.
                </p>
                <p>
                  On the other hand, the Arogya card is part of the Pradhan
                  Mantri Jan Arogya Yojana, which aims to provide health
                  insurance coverage to the economically weaker sections of
                  society with ₹5 lakhs per family every year for medical care
                  and hospitalisation.
                </p>
                <p>
                  <b>How does the Ayushman card work?</b>
                </p>
                <p>
                  With the help of the ABHA card ID generated, you can find
                  healthcare centres and share your records with them securely.
                  Using the ID, you can avail of various healthcare benefits and
                  maintain a record of your healthcare treatments,
                  consultations, etc.
                </p>
                <p>
                  <b>
                    Is it possible to revoke access to my health data after
                    access has been granted?
                  </b>
                </p>
                <p>
                  Yes, the ABDM allows you to revoke access or delete all your
                  medical data even after you have granted your healthcare
                  professional access to the record.
                </p>
                <p>
                  <b>Is it compulsory to register for an ABHA card?</b>
                </p>
                <p>No, registering for the ABHA card is not compulsory.</p>
                <p>
                  <b>What is the difference between PMJAY and ABHA?</b>
                </p>
                <p>
                  The Pradhan Mantri Jan Arogya Yojana (PMJAY) is an initiative
                  to improve and secure the livelihoods of people from
                  economically weaker sections of society. The Aarogya Bharat
                  Health Account or ABHA initiative is meant to encourage and
                  help people create a digital record of their medical
                  information.
                </p>
                <h3>
                  Disclaimer
                  <br />
                </h3>
                <ul>
                  <li>
                    This product is underwritten by MaxLife Insurance Company.
                    This plan is not a guaranteed issuance plan, and it will be
                    subject to Company’s underwriting and acceptance.
                  </li>
                  <li>
                    Insurance cover is available under this product. For more
                    details on risk factors, terms and conditions please read
                    Sales Brochure carefully before concluding a sale.
                  </li>
                  <li>
                    In case of sub-standard lives, extra premiums will be
                    charged as per our underwriting guidelines.
                  </li>
                  <li>
                    Buying a Life Insurance policy is a long-term commitment. An
                    early termination of the policy usually involves high costs,
                    and the Surrender Value payable may be less than the all the
                    Premiums Paid.
                  </li>
                  <li>
                    This publication is for general circulation only. This
                    document is for information and illustrative purposes only
                    and does not purport to any financial or investment services
                    and do not offer or form part of any offer or
                    recommendation. This document is not and should not be
                    regarded as investment advice or as a recommendation
                    regarding any particular security or course of action.
                  </li>
                  <li>
                    Please know the associated risks and the applicable charges,
                    from your insurance agent or the Intermediary or policy
                    document issued by the insurance company and this document
                    is not and should not be regarded as investment advice or as
                    a recommendation regarding any particular security or course
                    of action.
                  </li>
                </ul>
              </div>
            </Card.Text>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
export default AbhaCardContent;
