import { useState, useMemo } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import LoadingSpinner from "../common/LoadingSpinner";
import apiService from "../../service/apiService";
import { constant } from "../../constant/constant";
import { debounce } from "lodash";

export default function AbhaAddress(props) {
  const [abdmAddress, setAbdmAddress] = useState("");
  const [invalidInput, setInvalidInput] = useState(false);
  const [invalidInputMessage, setInvalidInputMessage] = useState("");
  const [validInput, setValidInput] = useState(false);
  const [validInputMessage, setValidInputMessage] = useState("");
  const [completeRegistrationButton, setCompleteRegistrationButton] =
    useState(true);
  const [hideLoader, setHideLoader] = useState(true);

  const goToDownloadAbhaForm = (e) => {
    e.preventDefault();
    let request_body = {
      healthId: abdmAddress,
      token: sessionStorage.getItem(constant.REACT_APP_token),
      updateHealthData: constant.REACT_APP_TRUE,
    };
    setHideLoader(false);
    setCompleteRegistrationButton(true);
    apiService(constant.REACT_APP_UPDATE_HEALTH_ID, request_body)
      .then((response) => {
        if (response.data) {
          if (response.data.errorMessage) {
            setInvalidInput(true);
            setInvalidInputMessage(response.data.errorMessage);
          } else {
            props.setDownloadAbhaForm(response.data);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setHideLoader(true);
        setCompleteRegistrationButton(false);
      });
  };

  const handleDebounceFn = async (address) => {
    let request_body = {
      healthId: address,
      searchHealthId: constant.REACT_APP_TRUE,
    };
    const response = await apiService(
      constant.REACT_APP_CHECK_HEALTHID_EXIST_OR_NOT,
      request_body
    );
    setValidInput(false);
    setInvalidInput(false);
    if (!response.data.status) {
      setValidInput(true);
      setValidInputMessage(constant.REACT_APP_ADDRESS_NOT_TAKEN);
      setCompleteRegistrationButton(false);
    } else {
      setInvalidInput(true);
      setInvalidInputMessage(
        constant.REACT_APP_ADDRESS_TAKEN +
          constant.REACT_APP_ADDRESS_SUGGESTION +
          response.data.suggestedHealthId
      );
      setCompleteRegistrationButton(true);
    }
  };
  const debounceFn = useMemo(() => debounce(handleDebounceFn, 2000), []);

  const setAbdmAdress = (e) => {
    setCompleteRegistrationButton(true);
    let address = e.target.value.toLowerCase();
    let n = address.length;
    setValidInput(false);
    setInvalidInput(false);
    if (n > 0) {
      if (/^[a-zA-Z][a-zA-Z0-9._]*$/.test(address)) {
        setAbdmAddress(address);
        debounceFn(address);
      } else {
        setInvalidInputMessage(constant.REACT_APP_SPECIAL_CHARACTER_MESSAGE);
        setInvalidInput(true);
      }
    } else {
      setAbdmAddress(address);
      setInvalidInputMessage("");
    }
  };

  return (
    <Form onSubmit={goToDownloadAbhaForm}>
      <Row className="mb-3" xs={1} lg={1}>
        <Form.Group as={Col} className="mb-2">
          <p className="content-text fw-bold">
            First Letter should be alphabet. Only
            alphabets,numericals,underscore and dot are allowed. For ex -
            john.doe_123
          </p>
        </Form.Group>
        <Form.Group as={Col} className="mb-2">
          <InputGroup className="mb-3">
            <Form.Control
              placeholder=""
              value={abdmAddress}
              onChange={setAbdmAdress}
              isInvalid={invalidInput}
              isValid={validInput}
              aria-label="abdm-address"
              aria-describedby="basic-addon1"
              autoComplete="off"
              minLength={4}
              maxLength={32}
            />
            <InputGroup.Text id="basic-addon1">@sbx</InputGroup.Text>
            <Form.Control.Feedback
              type="invalid"
              style={{ "white-space": "pre-line" }}
            >
              {invalidInputMessage}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid">
              {validInputMessage}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col} className="mb-2"></Form.Group>
      </Row>
      <Row className="mb-2" xs={1} lg={1}>
        <Col>
          <Button
            variant="primary"
            className="w-100 button-color"
            type="submit"
            disabled={completeRegistrationButton}
          >
            <LoadingSpinner show={hideLoader} />
            Complete Registration
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
