import React, { useState } from "react";
import { Button, Col, Image, Row } from "react-bootstrap";
import { PersonVcard, Search } from "react-bootstrap-icons";

function Landing({ setRegisterForm, setSearchForm }) {
  const [highlightButtonBorder1, setHighlightButtonBorder1] = useState(false);
  const [highlightButtonBorder2, setHighlightButtonBorder2] = useState(false);

  const goToRegisterForm = (e) => {
    setRegisterForm();
  };
  const goToSearchForm = (e) => {
    setSearchForm();
  };

  return (
    <>
      <Row className="mb-5" xs={1} lg={1}>
        <Col className="d-grid">
          <Button
            className={
              "button-color text-center " +
              (highlightButtonBorder1 ? "button-hover-color" : "")
            }
            onClick={goToRegisterForm}
            onMouseEnter={() => setHighlightButtonBorder1(true)}
            onMouseLeave={() => setHighlightButtonBorder1(false)}
          >
            <PersonVcard
              color="white"
              size={40}
              style={{ marginRight: "4%" }}
            />
            New ABHA Registration
          </Button>
        </Col>
      </Row>
      <Row className="mb-5" xs={1} lg={1}>
        <Col></Col>
        <Col className="d-grid">
          <Button
            className={
              "button-color text-center " +
              (highlightButtonBorder2 ? "button-hover-color" : "")
            }
            onClick={goToSearchForm}
            onMouseEnter={() => setHighlightButtonBorder2(true)}
            onMouseLeave={() => setHighlightButtonBorder2(false)}
          >
            <Search color="white" size={40} style={{ marginRight: "4%" }} />
            Search / Download ABHA Card
          </Button>
        </Col>
        <Col></Col>
      </Row>
      <div class="crossline"></div>
      <Row className="mt-3" xs={2} lg={2}>
        <Col className="d-grid justify-content-end pt-2 content-text">
          Approved by NHA
        </Col>
        <Col className="d-grid justify-content-start">
          <Image
            alt=""
            src="NHAimg.svg"
            width="79"
            height="48"
            className="d-inline-block align-top"
          />
        </Col>
      </Row>
    </>
  );
}

export default Landing;
